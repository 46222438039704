/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Mixins, Prop, VModel, Watch } from "vue-property-decorator";
import GlobalMixin from "@/mixins/GlobalMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import PaginateMixin from "@/mixins/PaginateMixin";
import FiltersMixin from "@/mixins/FiltersMixin";
import type { Collection, Model } from "@planetadeleste/vue-mc";
import { LoadingModule } from "@/store/loading";
import type { RawLocation, Route } from "vue-router";
import EventMixin from "./EventMixin";
import type { Response } from "vue-mc";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import { eqJson } from "@/plugins/helpers";
import {
  camelCase,
  chain,
  cloneDeep,
  debounce,
  delay,
  endsWith,
  get,
  invoke,
  isArray,
  isEmpty,
  isFunction,
  isNil,
  isUndefined,
  omit,
  remove,
  snakeCase,
  some,
  union,
  unset,
  upperFirst,
} from "lodash";
import { number } from "mathjs";
import { cleanCache } from "@/plugins/axios";
import type { Subjects } from "@/services/ability";

type ConstructorOf<A> = new (...args: any[]) => A;

@Component
export default class ModelMixin<
  A extends Model = Model,
  B extends Collection<A> = Collection<A>
> extends Mixins(
  GlobalMixin,
  DataTableMixin,
  PaginateMixin,
  FiltersMixin,
  EventMixin
) {
  @VModel({ type: Object, default: () => ({}) }) obModel!: A;
  @Prop(String) readonly filterKey!: string;

  obModelClass!: ConstructorOf<A>;
  obCollectionClass!: ConstructorOf<B>;
  obCollection!: B;
  obItem: A | Record<string, any> = {};
  sRoutePath: string | null = null;
  sRouteName: string | null = null;
  displayForm = false;
  previewItem = false;
  autoSaveForm = false;
  continueEditing = false;
  hideActionsHeader = false;
  routeLoading = false;
  isList = false;

  obCurrentFilters: string | null = null;

  /**
   * @var {boolean} forceSave Force save model even not are dirty
   */
  forceSave = false;

  get modelClassName(): Subjects {
    return (this.obModelClass ? this.obModelClass.name : "global") as Subjects;
  }

  get sCollectionName() {
    return this.obCollection ? snakeCase(this.obCollection.$class) : null;
  }

  get sModelName() {
    const sName =
      this.modelClassName !== "global"
        ? this.modelClassName
        : this.obModel
        ? this.obModel.constructor.name
        : null;
    return sName ? snakeCase(sName) : null;
  }

  get sModelFilterKey() {
    let sClassName = this.modelClassName as string;

    if (this.filterKey) {
      return (sClassName += upperFirst(camelCase(this.filterKey)));
    }

    return sClassName;
  }

  get isListing() {
    return !this.isForm;
  }

  get isForm() {
    const sRouteName = this.$route.name;
    return sRouteName
      ? some(["create", "update"], (sKey) => {
          return endsWith(sRouteName, sKey);
        })
      : false;
  }

  get canCreate() {
    return (
      this.$ability.can("manage", "all") ||
      this.$ability.can("create", this.modelClassName)
    );
  }

  get canUpdate() {
    return (
      this.$ability.can("manage", "all") ||
      this.$ability.can("update", this.modelClassName)
    );
  }

  get canDelete() {
    return (
      this.$ability.can("manage", "all") ||
      this.$ability.can("delete", this.modelClassName)
    );
  }

  get canRead() {
    if (!this.obCollection) {
      return true;
    }

    return (
      this.$ability.can("manage", "all") ||
      this.$ability.can("read", this.modelClassName)
    );
  }

  get isDirty() {
    return this.obModel && this.obModel.isExisting()
      ? this.obModel.isDirty()
      : true;
  }

  mounted() {
    this.emit("model.mounted", this.sModelName);

    this.$nextTick(() => {
      this.iKeyReactive++;

      this.emit("model.nextTick", this.sModelName);
      invoke(this, "onMounted");

      if (this.isListing) {
        if (!this.hideActionsHeader) {
          this.addDTActionsHeader();
        }

        this.mapDTHeaders();
      }
    });
  }

  onCreated() {
    this.iKeyReactive++;

    if (this.isList) {
      this.addEvent("filters.change", this.onChangeFilters);
      this.addEvent(`${this.sModelName}.after.save`, this.index);
      this.addEvent(`${this.sModelName}.after.delete`, this.index);
      this.addEvent("reload.index", this.index);
    }

    this.addEvent("after.close.view", this.onCloseView);
    this.addEvent("close.view", this.closeView);

    invoke(this, "onRegisterEvents");
    this.registerEvents();

    if (this.sRouteName) {
      this.sRoutePath = this.$router.resolve({ name: this.sRouteName }).href;
    }

    if (!this.obModel || isEmpty(this.obModel)) {
      this.$emit("input", this.defaultModel());
    }

    if (!this.modelFilters || isEmpty(this.modelFilters)) {
      this.onSetFilters({});
    }

    this.iKeyReactive++;
  }

  beforeDestroy() {
    this.unregisterEvents();
    invoke(this, "onBeforeDestroy");
  }

  // Auto save model if autoSaveForm is enabled
  @Watch("obModel")
  onChangeModel(obModel: A) {
    if (isNil(obModel) || isEmpty(obModel)) {
      return;
    }

    obModel.on("change", debounce(this.autoSave, 1000));
  }

  @Watch("$route", { immediate: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRouteChanged(to?: Route, from?: Route) {
    if (this.routeLoading) {
      return;
    }

    if (!to) {
      to = this.$route;
    }

    this.routeLoading = true;
    let arRoutes = [];
    let sRoute = "";

    // Fill base route name
    if (this.sRouteName) {
      sRoute = this.sRouteName.includes(".")
        ? chain(this.sRouteName).split(".").first().value()
        : this.sRouteName;
    } else if (this.sRoutePath) {
      sRoute = chain(this.sRoutePath).trim("/").split("/").first().value();
    }

    if (!sRoute) {
      this.routeLoading = false;
      return;
    }

    if (this.canRead) {
      arRoutes.push(`${sRoute}.view`);
    }

    if (this.canUpdate) {
      arRoutes.push(`${sRoute}.update`);
    }

    if (this.canCreate) {
      arRoutes.push(`${sRoute}.create`);
      arRoutes.push(`${sRoute}.copy`);
    }

    const arInvokedRoutes = invoke(this, "onExtendRouteChanged", arRoutes);
    if (!isEmpty(arInvokedRoutes)) {
      arRoutes = union(arRoutes, arInvokedRoutes);
    }

    if (to.name && arRoutes.length && arRoutes.includes(to.name)) {
      const sId = to.params.id ? number(to.params.id) : null;
      this.onUpdateOrCreate(sId);
      return;
    }

    this.hideForm();
    this.closeView();
    this.routeLoading = false;
  }

  async autoSave() {
    if (!this.autoSaveForm || !this.isDirty) {
      return;
    }

    const arChangedKeys = this.obModel.changed();

    if (!isArray(arChangedKeys)) {
      return;
    }

    remove(arChangedKeys, (sKey) => sKey == "css");

    if (arChangedKeys.includes("preview_image")) {
      // @ts-ignore
      if (this.obModel.preview_image instanceof Blob) {
        remove(arChangedKeys, (sKey) => sKey == "preview_image");
      }
    }

    if (isEmpty(arChangedKeys)) {
      return;
    }

    this.continueEditing = true;
    await this.save();
    this.continueEditing = false;
  }

  async save() {
    if (!this.obModel || (!this.forceSave && !this.isDirty)) {
      return;
    }

    this.emit(`${this.sModelName}.before.sync`, this.obModel);

    // Copy original model
    this.obModel.sync();
    const obModel: A = new this.obModelClass(this.obModel.attributes);

    this.emit(`${this.sModelName}.before.save`, obModel);
    invoke(this, "saving", obModel);

    LoadingModule.loading();
    obModel.sync();
    const isNew = obModel.isNew();

    const response = await obModel
      .store()
      .then((response) => response?.getData())
      .catch((reason) => {
        this.evalResponse(reason);
        this.continueEditing = false;
      });

    LoadingModule.loaded();

    if (!response) {
      return;
    }

    this.evalResponse(response);

    if (response.data && isNew && this.continueEditing) {
      this.obModel.set(response.data);
    }

    cleanCache();
    await this.cancel();
    invoke(this, "saved", response);

    delay(() => {
      this.emit(`${this.sModelName}.after.save`, response.data);
    }, 500);
  }

  defaultModel(obProperties?: Record<string, any>): A {
    return new this.obModelClass(obProperties);
  }

  async cancel() {
    if (
      !this.obModelClass ||
      !this.sRoutePath ||
      this.continueEditing ||
      this.routeIs("list")
    ) {
      return;
    }

    this.$emit("input", this.defaultModel());

    if (this.$route.path == this.sRoutePath || this.routeIs("list")) {
      return;
    }

    let sLocation: RawLocation = this.sRoutePath;

    if (this.$route.name && this.$route.name.includes(".")) {
      const arRouteNameList = this.$route.name.split(".");
      arRouteNameList.pop();
      arRouteNameList.push("list");
      sLocation = { name: arRouteNameList.join(".") };
    }

    await this.$router.push(sLocation).catch(() => {});
  }

  onChangeFilters(sContainer: string) {
    const obData = get(this.filters, sContainer, {});

    if (
      sContainer === this.sModelFilterKey &&
      (!this.obCurrentFilters || !eqJson(obData, this.obCurrentFilters))
    ) {
      this.obCurrentFilters = JSON.stringify(obData);
      this.index();
    }
  }

  async index() {
    if (!this.obCollection || this.bLocalLoading) {
      return;
    }

    this.bLocalLoading = true;
    const obCollection = this.obCollectionClass
      ? new this.obCollectionClass()
      : this.obCollection.clone();

    //@ts-ignore
    obCollection.clearFilters();
    obCollection.clear();
    const obFilters =
      isNil(this.modelFilters) || isEmpty(this.modelFilters)
        ? {}
        : cloneDeep(this.modelFilters);

    invoke(this, "onBeforeIndex", obFilters);
    this.emit(`${this.sCollectionName}.before.index`, obCollection);

    // Call onValidateIndex method if exists
    // If returned value is false not continue with index method
    const valid = invoke(this, "onValidateIndex", obFilters);
    if (valid === false) {
      this.bLocalLoading = false;
      return;
    }

    // @ts-ignore
    obCollection.filterBy(omit(obFilters, "page"));
    obCollection.page(this.currentPage);

    LoadingModule.loading();
    const response = await obCollection.fetch();
    this.obCollection.clear();

    if (response) {
      const obData = response.getData();
      this.mapPagination(obData);
      this.obCollection.add(obData.data);
    }

    invoke(this, "onAfterIndex");

    delay(() => {
      this.emit(`${this.sCollectionName}.after.index`, this.obCollection);
      this.bLocalLoading = false;
      LoadingModule.loaded();
    }, 500);
  }

  async deleteItem(iItemId: number) {
    // @ts-ignore
    const obModel: A | undefined = this.obCollection.find({ id: iItemId });
    if (!obModel) {
      return;
    }

    await this.remove(obModel);
  }

  async remove(obModel: A, sText = "ask.remove.record") {
    if (!obModel) {
      return;
    }

    if (!isFunction(obModel.delete)) {
      obModel = this.defaultModel(obModel);
    }

    const sMessage = this.$t(sText) as string;
    const bRes = await this.$confirm(sMessage, { color: "warning" });
    if (bRes) {
      const obModelData = obModel.attributes;
      const obResponse = await obModel
        .delete()
        .then((response) => response?.getData())
        .catch((reason) => {
          this.evalResponse(reason);
        });

      if (!obResponse) {
        return;
      }

      this.evalResponse(obResponse);

      cleanCache();
      this.deleted();
      delay(() => {
        this.emit(`${this.sModelName}.after.delete`, {
          response: obResponse,
          model: obModelData,
        });
      }, 500);

      return obResponse;
    }
  }

  deleted() {
    // LayoutModule.setReloadOn();
  }

  onUpdateOrCreate(iID?: number | null) {
    if (isUndefined(this.obCollection) || this.bLocalLoading) {
      return;
    }

    const view = this.routeIs("view");

    if (iID) {
      this.bLocalLoading = true;
      const obItem = this.defaultModel();
      obItem.set("id", iID);

      this.emit(`${this.sModelName}.before.fetch`, obItem);

      obItem.fetch().then((response: Response<InvoiceData> | null) => {
        if (response) {
          const obData = response.getData().data;

          if (this.$route.name && this.$route.name.includes("copy")) {
            unset(obData, "id");
          }

          this.obItem = this.defaultModel(obData);
          // @ts-ignore
          this.obItem.unset("data");
          this.emit(`${this.sModelName}.after.fetch`, obData);
        }

        if (view) {
          this.openView();
        } else {
          this.showForm();
        }

        this.bLocalLoading = false;
        this.routeLoading = false;
      });

      return;
    }

    const obModel: A | undefined = iID
      ? this.obCollection.find({ id: iID })
      : this.defaultModel();

    this.obItem = this.defaultModel(obModel ? obModel.attributes : {});
    // @ts-ignore
    this.obItem.unregisterCollection(this.obCollection);
    this.routeLoading = false;
    this.emit(`${this.sModelName}.after.create`, this.obItem);

    if (view) {
      this.openView();
    } else {
      this.showForm();
    }
  }

  showForm() {
    if (this.displayForm) {
      return;
    }

    this.closeView();
    this.emit("before.show.form", this.obItem);
    this.displayForm = true;
    this.emit("after.show.form", this.obItem);
  }

  hideForm() {
    if (!this.displayForm) {
      return;
    }

    this.emit("before.hide.form");
    this.displayForm = false;
    this.emit("after.hide.form");
  }

  openView() {
    if (this.previewItem) {
      return;
    }

    this.hideForm();
    this.emit("before.open.view", this.obItem);
    this.previewItem = true;
    this.emit("after.open.view", this.obItem);
  }

  closeView() {
    if (!this.previewItem) {
      return;
    }

    this.emit("before.close.view", this.obItem);
    this.previewItem = false;
    this.emit("after.close.view", this.obItem);
  }

  onCloseView() {
    if (this.routeIs("view")) {
      this.cancel();
    }
  }

  routeIs(sValue: string) {
    return !!this.$route.name && endsWith(this.$route.name, sValue);
  }
}
